* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

/* Colors */
:root {
	--darkBlue: #183150;
	--white: #ffffff;
	--darkBlack: #1d1b1a;
	--lightBG: #dedede;
	--MainBG: #e9e4de;
	--blueBG: #abd1ff;
	--generalPadding: 64px 64px;
}

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;600;700;800&display=swap");
h1 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 700;
	font-size: 172px;
	line-height: 188px;
	letter-spacing: -0.02em;
}
h2 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 80px;
	line-height: 94px;
	letter-spacing: -0.02em;
	/* border: 2px solid #183150; */
}
h2.outlinedB {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 80px;
	line-height: 94px;
	letter-spacing: -0.02em;
	color: transparent;
	-webkit-text-stroke: 2px var(--darkBlue);
}
h2.outlinedW {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 80px;
	line-height: 94px;
	letter-spacing: -0.02em;
	/* border: 2px solid #183150; */
	color: transparent;

	-webkit-text-stroke: 2px white;
}
h3 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 64px;
	line-height: 94px;
	letter-spacing: -0.02em;
}
h4 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 56px;
	line-height: 80px;
	letter-spacing: -0.02em;
}
h5 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 48px;
	letter-spacing: -0.02em;
}
h6 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.02em;
}

.b28 {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	letter-spacing: 0.02em;
}
.b20 {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.02em;
}
.b16 {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.btnText18 {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: right;
	letter-spacing: 0.02em;
}

.btnText20 {
	font-family: "Arial";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.02em;
}

.btnText24 {
	font-family: "Arial";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.inputFieldText {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.navText {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 32px;
	text-align: right;
	letter-spacing: 0.02em;
	text-decoration: none;
	text-transform: uppercase;
	opacity: 0.6;
	color: white;
	transition: 0.4s ease;
	cursor: pointer;
}
.navText.active {
	text-decoration-line: underline;
	opacity: 1;
}
.navText:hover {
	opacity: 1;
}

@media only screen and (max-width: 600px) {
	:root {
		--generalPadding: 48px 24px;
	}
	h1 {
		font-size: 74px;
		line-height: 80px;
	}
	h2 {
		font-size: 56px;
		line-height: 72px;
	}
	h2.outlinedB {
		font-size: 56px;
		line-height: 72px;
		-webkit-text-stroke: 0px white;
		color: var(--darkBlue);
		padding-top: 64px;
		/* font-size: 64px;
        line-height: 76px; */
	}
	h2.outlinedW {
		font-size: 56px;
		line-height: 72px;
		-webkit-text-stroke: 0px white;
		color: white;
		padding-top: 64px;
		/* font-size: 64px;
        line-height: 76px; */
	}
	h3 {
		font-size: 48px;
		line-height: 60px;
	}
	h4 {
		font-size: 32px;
		line-height: 40px;
	}
	h5 {
		font-family: "Prompt";
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 48px;
		letter-spacing: -0.02em;
	}
	h6 {
		font-family: "Prompt";
		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 0.02em;
	}

	.b28 {
		font-family: "Arial";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: 0.02em;
	}
	.b20 {
		font-family: "Arial";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 0.02em;
	}
	.b16 {
		font-family: "Prompt";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}

	.btnText18 {
		font-family: "Arial";
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		text-align: right;
		letter-spacing: 0.02em;
	}

	.btnText20 {
		font-family: "Arial";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -0.02em;
	}

	.btnText24 {
		font-family: "Arial";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.02em;
	}

	.inputFieldText {
		font-family: "Arial";
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.02em;
	}

	.navText {
		font-family: "Arial";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 32px;
		text-align: right;
		letter-spacing: 0.02em;
		text-decoration: none;
		text-transform: uppercase;
		opacity: 0.6;
		color: white;
		transition: 0.4s ease;
		cursor: pointer;
	}
	.navText.active {
		text-decoration-line: underline;
		opacity: 1;
	}
	.navText:hover {
		opacity: 1;
	}
}

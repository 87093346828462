.roller {
	height: 196px;
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	color: #1d3557;
}

.roller h1 {
	text-align: left;
	font-size: 176px;
	line-height: 196px;
	letter-spacing: -0.02em;
	will-change: transform;
}

@media screen and (max-width: 600px) {
	.roller {
		height: 116px;
	}

	.roller h1 {
		font-size: 90px;
		line-height: 124px;
	}
}

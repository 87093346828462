html {
    scroll-behavior: smooth;
}
body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.App {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    width: 100vw;
}
.App .Content {
    /* margin-left: 64px; */
    width: calc(100vw);
}
